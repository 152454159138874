import React from "react"
import { graphql } from "gatsby"

import PostsTagTemplate from "../PostsTagTemplate"

const ArticlesTag = ({ data: { articles }, pageContext: { tag } }) => {
  return <PostsTagTemplate posts={articles} type="learn" tag={tag} />
}

export default ArticlesTag

export const query = graphql`
  query ArticlesTagSlug($tag: [String]) {
    articles: allContentfulArticle(
      filter: { slug: { ne: "placeholder" }, tags: { in: $tag } }
    ) {
      edges {
        node {
          ...Article
        }
      }
    }
  }
`
